import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import BlogPostList from "../components/BlogPostList"
import LinkContainer from '../components/LinkContainer'

const BlogPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ogImg: file(relativePath: { eq: "images/handy_pro-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <SEO
            title="All Posts"
            keywords={[`blog`, `weblog`, `Wesley L. Handy`]}
            image={{
              src: data.ogImg.childImageSharp.fluid.src,
              height: "1200",
              width: "630",
              alt: "Wesley L. Handy, Full Stack Web Developer, at your service"
            }}
            description="Read Wesley's recent thoughts on technical issues facing JavaScript developers today."
          />
          <BlogPostList />
          <LinkContainer>
            <Link to="/">Go back to Home</Link>
          </LinkContainer>
        </Layout>
      )
    }}
  />
)

export default BlogPage
